import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Grievance from './pages/Grievance';
import LendingPartner from './pages/LendingPartner';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50 text-gray-800 font-sans flex flex-col">
        <header className="bg-white shadow-sm">
          <div className="container mx-auto px-4 py-4 sm:py-6">
          <Link to="/" > <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="LinePe logo" width="120" height="100" /></Link> 
          </div>
        </header>

        <main id="about" className="container mx-auto px-4 py-8 sm:py-12 md:py-16 lg:py-20 flex-grow flex flex-col justify-center space-y-8 sm:space-y-12 md:space-y-16">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/lending-partners" element={<LendingPartner />} />
            <Route path="/grievance" element={<Grievance />} />
          </Routes>
        </main>

        <footer className="bg-gray-800 text-white py-6 sm:py-8 mt-8 sm:mt-12">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center gap-3 sm:gap-4 mb-4 sm:mb-6 text-xs sm:text-sm">  
             <Link to="/" className="hover:text-blue-300 transition duration-300">About</Link>
             <Link to="/contact" className="hover:text-blue-300 transition duration-300">Contact</Link>
             <a href="privacy-policy.html" target="_blank" className="hover:text-blue-300 transition duration-300">Privacy Policy</a>
             <a href="terms-conditions.html" target="_blank" className="hover:text-blue-300 transition duration-300">Terms and Conditions</a>
             <a href="refund-policy.html" target="_blank" className="hover:text-blue-300 transition duration-300">Refund Policy</a>
             <Link to="/lending-partners" className="hover:text-blue-300 transition duration-300">Lending Partner</Link>
             <Link to="/grievance" className="hover:text-blue-300 transition duration-300">Grievance</Link>

            </div>
            <p className="text-center text-xs sm:text-sm">&copy; 2024 LinePe | NEOWAY TECHNOLOGIES PRIVATE LIMITED</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;

// const LandingPage = () => {
//   return (
//     <div className="min-h-screen bg-gray-50 text-gray-800 font-sans flex flex-col">
//       <header className="bg-white shadow-sm">
//         <div className="container mx-auto px-4 py-4 sm:py-6">
//            <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="LinePe logo"  width="120" height="100" />
//         </div>
//       </header>

//       <main className="flex-grow">
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/grievance" element={<Grievance />} />
//           </Routes>
//       </main>

//       <main id="about" className="container mx-auto px-4 py-8 sm:py-12 md:py-16 lg:py-20 flex-grow flex flex-col justify-center space-y-8 sm:space-y-12 md:space-y-16">
        
//         <section className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-2xl shadow-xl max-w-4xl mx-auto w-full">
//           <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6 sm:mb-8 md:mb-10">    
//           {/* <span style={{color:"#1f285a"}}>Line</span><span style={{color:"rgb(255, 152, 0)"}}>Pe</span>   */}
//           Our Lending Partner</h2>
//           <div className="grid grid-cols-1">
//           <div className="partner_grid justify-content-center">
//                     <div className="partner_content">
//                     <img src={`${process.env.PUBLIC_URL}/assets/gromor.png`}  width="150" height="100" alt="Gromor logo"  />

//                         <div className="center">
//                             <svg width="100%" height="2" viewBox="0 0 330 2" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                 <path d="M0 1H330" stroke="#D6D6D6"></path>
//                             </svg>

//                         </div>

//                         <div className="partner_details text-black">
//                             <h5 className="text-center text-uppercase mb-5">GROMOR FINANCE</h5>
//                             {/* <p className="leanding"> <Users size={16} /> Santosh Sinha</p> */}
//                             <p className="leanding"><Phone size={16} /> <a href="tel:02261344779">02261344779</a></p>
//                             <p className="leanding"><Globe size={16} /> <a href="https://gromor.in/" target="blank">https://gromor.in/</a></p>
//                         </div>
//                     </div>
//                </div>
//           </div>
//         </section>

       
//       </main>

//       {/* <Router>
//             <Routes>
               
//                 <Route path="/about" element={<About />} />
                
//             </Routes>
//         </Router>
//         <Navbar /> */}
//       <footer className="bg-gray-800 text-white py-6 sm:py-8 mt-8 sm:mt-12">
//         <div className="container mx-auto px-4">
       
//           <div className="flex flex-wrap justify-center gap-3 sm:gap-4 mb-4 sm:mb-6 text-xs sm:text-sm">
//             <a href="#about" className="hover:text-blue-300 transition duration-300">About</a>
//             <a href="contact.html" className="hover:text-blue-300 transition duration-300">Contact</a>
//             <a href="privacy-policy.html" target="_blank" className="hover:text-blue-300 transition duration-300">Privacy Policy</a>
//             <a href="terms-conditions.html" target="_blank" className="hover:text-blue-300 transition duration-300">Terms and Conditions</a>
//             <a href="refund-policy.html" target="_blank" className="hover:text-blue-300 transition duration-300">Refund Policy</a>
//             <a href="#lending-partner" className="hover:text-blue-300 transition duration-300">Lending Partner</a>
//             <a href="#grievance" className="hover:text-blue-300 transition duration-300">Grievance</a>
//           </div>          
//         <p className="text-center text-xs sm:text-sm">&copy; 2024 LinePe | NEOWAY TECHNOLOGIES PRIVATE LIMITED</p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default LandingPage;
