import React from 'react';
import { Download, DollarSign, Users, TrendingDown } from 'lucide-react';

const Home = () => {
  return (
    <><section className="bg-blue-600 text-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-2xl shadow-xl max-w-4xl mx-auto w-full">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-4 sm:mb-6">Our mission</h2>
          <p className="text-lg sm:text-xl md:text-2xl text-center leading-relaxed">
              Our mission is to increase income for our members –
              the dairy product distributors in India.
          </p>
      </section><section className="text-center">
              <a href="https://play.google.com/store/apps/details?id=neoway.linepe&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-600 text-white px-6 sm:px-8 md:px-10 py-3 sm:py-4 rounded-full text-base sm:text-lg md:text-xl font-semibold hover:bg-blue-700 transition duration-300 shadow-lg transform hover:scale-105">
                  Download Our App <Download className="inline ml-2" size={20} />
              </a>
          </section><section className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-2xl shadow-xl max-w-4xl mx-auto w-full">
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6 sm:mb-8 md:mb-10">How We Help</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
                  <div className="text-center">
                      <DollarSign className="mx-auto text-blue-600 mb-4" size={32} />
                      <h3 className="text-lg sm:text-xl font-semibold mb-2">Low-Cost Capital</h3>
                      <p className="text-sm sm:text-base text-gray-600">Connecting our members with RBI registered lenders for credit lines</p>
                  </div>
                  <div className="text-center">
                      <Users className="mx-auto text-blue-600 mb-4" size={32} />
                      <h3 className="text-lg sm:text-xl font-semibold mb-2">Network Monetization</h3>
                      <p className="text-sm sm:text-base text-gray-600">Maximize profits from your existing retailer network</p>
                  </div>
                  <div className="text-center sm:col-span-2 md:col-span-1">
                      <TrendingDown className="mx-auto text-blue-600 mb-4" size={32} />
                      <h3 className="text-lg sm:text-xl font-semibold mb-2">Cost Reduction</h3>
                      <p className="text-sm sm:text-base text-gray-600">Insights to optimize your daily supply chain costs</p>
                  </div>
              </div>
          </section></>
  );
};

export default Home;

     
