import React from 'react';
const Contact = () => {
  return (
    <>

<section className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 rounded-2xl shadow-xl max-w-4xl mx-auto w-full">
           <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-6 sm:mb-8 md:mb-10">    
           Contact us</h2>
           <p>For any concern or help you can reach us at:</p>
              <div className="container" style={{padding: '10px 0px'}}>
                  <div className="partner_grid justify-content-center">
                      <div className="partner_content">                     
                          <div className="partner_details contact-details" style={{backgroundColor:'#0073e6'}}>
                              <p style={{ fontSize:'1.5em', fontWeight:'700', color:'#fff'}}>NEOWAY TECHNOLOGIES PRIVATE LIMITED</p>
                              <p style={{color:'#fff'}}> WeWork Rajapushpa Summit, Financial District, Hyderabad, Telangana 500032, India</p>
                              <p style={{ fontSize:'1.2em', fontWeight:'700', color:'#fff'}}>Email: </p>
                              <p><a href="mailto:hello@neoway.in" style={{color:'#fff'}}>Hello@linepe.in</a></p>
                          </div>
                      </div>                  
                  </div>
                  
              </div>
              
         </section>
    </>
  );
};

export default Contact;